import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { READ_ONLY_ROLES, constants } from '@shared/constants';
import { fromAuth } from '@shared/store';

@Injectable({
  providedIn: 'root',
})
export class AccessControlGuard {
  router = inject(Router);

  store = inject(Store);

  userRole$ = this.store.select(fromAuth.selectUserRole);

  canActivate(): Observable<boolean> {
    return this.userRole$.pipe(
      map((role) => {
        if (READ_ONLY_ROLES.includes(role)) {
          this.router.navigate([constants.HOME_ROUTE]);
          return false;
        }

        return true;
      }),
    );
  }
}
