import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { constants } from '@shared/constants';
import { fromAuth } from '@shared/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  isAuthenticated$ = this.store.select(fromAuth.selectLoggedIn);

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        }

        this.router.navigate([constants.AUTH_ROUTE], this.getReturnUrl(state.url));

        return false;
      }),
    );
  }

  getReturnUrl(url?: string): NavigationExtras | undefined {
    if (!url || url === constants.HOME_ROUTE) {
      return;
    }

    return {
      queryParams: {
        returnUrl: url,
      },
    };
  }
}
