import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { validate as uuidValidate } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ValidUUIDGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const uuid: string | null = route.paramMap.get('id');
    if (!uuid || uuidValidate(uuid)) {
      return true;
    } else {
      this.router.navigate(['/404']);
      return false;
    }
  }
}
